<!--
 * @Author: CL
 * @Date: 2021-06-25 10:07:21
 * @LastEditTime: 2021-06-28 15:05:18
 * @Description: 项目展示组件
-->

<template>
  <div class="projectrect-contain" :class="{ right: right }">
    <div class="text-wrap" :class="{ right: right }">
      <span class="text-title" @click="handleJump(info.address)">{{ info.title }}</span>
      <div class="text-time">{{ info.createTime }}</div>
      <div class="text-label">
        <div class="label-view">
          <IconFont type="views" />
          <span>{{ info.views }}</span>
          <div class="label-view-wrap">
            <Pop text="浏览次数" />
          </div>
        </div>
        <div class="label-likes">
          <IconFont type="like" />
          <span> {{ info.likes }} </span>
          <div class="label-likes-wrap">
            <Pop text="点赞次数" />
          </div>
        </div>
      </div>
    </div>
    <div @click="handleJump(info.address)" class="img-wrap">
      <img v-imglazy="baseURL + info.picture" :alt="info.title" :title="info.title">
    </div>
  </div>
</template>

<script>
import IconFont from '@/components/IconFont';
import { mapState } from 'vuex';
import Pop from '@/components/Pop';

export default {
  props: {
    //文字是否是在右边
    right: {
      type: Boolean,
      default: false
    },

    info: {
      type: Object,
      required: true
    }
  },

  components: {
    IconFont,
    Pop
  },

  computed: {
    ...mapState(['baseURL'])
  },

  methods: {
    //跳转到效果页面
    handleJump(url){
      window.open(url);
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/var.less';

//媒体查询
@media screen and (max-width: 700px) {
  .projectrect-contain{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 !important;
    min-width: 500px;

    .text-wrap{
      width: 100% !important;
      order: 3 !important;
      height: 100px !important;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }

    .img-wrap{
      width: 100% !important;
    }
  }
}

.projectrect-contain{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 100px;
  padding: 0 100px;
  box-sizing: border-box;

  &.right{
    justify-content: flex-end;
  }

  .text-wrap{
    width: 400px;
    height: 250px;
    border: 1px solid @menutext;
    order: 1;
    border-radius: 5px;
    padding: 50px 50px;
    box-sizing: border-box;

    &.right{
      order: 3;
    }

    .text-title{
      font-size: 24px;
      display: inline-block;

      &:hover{
        cursor: pointer;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 4'%3E%3Cpath fill='none' stroke='%23599' d='M0 3.5c5 0 5-3 10-3s5 3 10 3 5-3 10-3 5 3 10 3'/%3E%3C/svg%3E") repeat-x 0 100%; 
        background-size: 20px auto;
        animation: move 1s infinite linear;
      }
    }

    .text-label{
      margin-top: 15px;
      display: flex;
      align-items: center;
      
      > div{
        position: relative;
        display: flex;
        align-items: center;
        margin: 0 20px 0 0;
        cursor: pointer;

        &.label-view:hover{
          .label-view-wrap{
            opacity: 1;
          }
        }

        &.label-likes:hover{
          .label-likes-wrap{
            opacity: 1;
          }
        }

        .label-view-wrap, .label-likes-wrap{
          opacity: 0;
          transition: opacity 1s;
        }

        >span{
          margin-left: 5px;
        }
      }
    }

    .text-time{
      margin-top: 15px;
      font-size: 12px;
      color: @info;
    }
  }

  .img-wrap{
    min-width: 400px;
    width: 50%;
    height: 350px;
    order: 2;
    border-radius: 5px;
    position: relative;
    cursor: pointer;

    img{
      width: 100%;
      height: 100%;
      border-radius: 5px;
      object-fit: cover;
      opacity: 0;
      animation: show 1s forwards;
    }

    &::after{
      content: '';
      display: block;
      left: 50%;
      top: 0;
      position: absolute;
      width: 1px;
      height: 100px;
      background: @menutext;
      transform: translateX(-50%) translateY(-100%) rotateZ(180deg);
    }
  }
}

//波浪线动画
@keyframes move{
  from { background-position: 0 100%; }
  to   { background-position: -20px 100%; }
}

@keyframes show{
  0%{
    opacity: 0;
  }

  100%{
    opacity: 1;
  }
}
</style>
