<!--
 * @Author: CL
 * @Date: 2021-06-24 23:24:22
 * @LastEditTime: 2021-08-01 23:23:48
 * @Description: 代码&效果
-->

<template>
  <div class="code-contain" ref="code" @scroll="handleScroll">
    <GoHome />
    <ProjectRect 
      v-for="(item,index) in codeList" 
      :key="item.id" 
      :info="item"
      :right="(index + 1) % 2 == 0" 
    />
    <LoadingMore :isShow="isLoadingMore" :bottom="50" />
    <NoMore :isShow="hasMore" word="项目" />
    <!-- <ToTop :isShow="isShow" /> -->
    <Rocket :isLaunch="isShow" :isLand="isLand" />
  </div>
</template>

<script>
import { queryProject } from '@/api/project.js';
import ProjectRect from './components/projectRect.vue';
import GoHome from '@/components/GoHome';
import LoadingMore from '@/components/LoadingMore';
// import ToTop from '@/components/ToTop';
import NoMore from '@/components/NoMore';
import Rocket from '@/components/Rocket';

export default {
  data(){
    return {
      page: 1,
      size: 2,
      total: 0,
      codeList: [],
      loading: true,
      isLoadingMore: false,
      isShow: false,
      isLand: false,
      hasMore: false
    }
  },

  methods: {
    /**
     * 分页查询效果
     */
    async queryProject(){
      this.loading = true;
      try{
        const { page, size } = this;
        const res = await queryProject(page, size, { isGame: false });
        this.loading = false;
        if(res.code == 200){
          const { count, rows } = res.data;
          this.total = count;
          this.codeList = rows;
        }
      }catch(err){
        console.log(err);
      }
    },

    /**
     * 加载更多
     */
    async fetchMore(){
      if(this.total == this.codeList.length){
        this.hasMore = true;
        return;
      };
      this.page++;
      this.isLoadingMore = true;
      try{
        const res = await queryProject(this.page, this.size, { isGame: false });
        await this.$utils.delay(1500);
        this.isLoadingMore = false;
        if(res.code == 200){
          this.codeList = [...this.codeList, ...res.data.rows]
        }
      }catch(err){
        console.log(err);
      }
    },

    /**
     * 处理滚动事件
     */
    handleScroll(){
      if(this.$refs.code.scrollTop == 0){
        this.isShow = true;
        this.isLand = false;
      }else if(this.$refs.code.scrollTop > 0){
        this.isLand = true;
      }

      this.$bus.$emit('mainScroll', this.$refs.code);
      if(this.$refs.code.scrollTop + this.$refs.code.clientHeight >= this.$refs.code.scrollHeight - 50){
        this.fetchMore();
      }
    },

    handleScrollTop(){
      this.$refs.code.scrollTop = 0;
      setTimeout(() => {
        this.isShow = true;
        this.isLand = false;
      }, 500)
    }
  },

  created(){
    this.queryProject();
  },

  mounted(){
    this.$bus.$on('scrollTop', this.handleScrollTop);
  },

  destroyed() {
    this.$bus.$off('scrollTop', this.handleScrollTop);
  },

  components: {
    ProjectRect,
    GoHome,
    LoadingMore,
    // ToTop,
    NoMore,
    Rocket
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/mixin.less';

.code-contain{
  .self-fill();
  overflow-y: scroll;
  padding: 50px 0 0 0;
  box-sizing: border-box;
  scroll-behavior: smooth;

  background-image:linear-gradient(125deg, rgba(250, 250, 250, 0.8), rgba(245, 245, 245, 0.6), rgba(240, 240, 240, 0.9), rgba(235, 235, 235, 0.3));
	/* 让每一种颜色占据400% */
  background-size: 400%;   
  animation: move 15s infinite;

  .nomore-contain{
    padding-bottom: 30px;
  }
}

@keyframes move {
	0% {
    background-position: 0% 50%;
  }
  50% {
	  background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>
